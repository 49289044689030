import './App.css';
import Index from './components/Index';


function App() {
  return (
   <Index/>
  );
}

export default App;
